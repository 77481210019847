import React from "react";
import theme from "theme";
import { Theme, Link, Section, Box, Text } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				ON THE RAZZLE
			</title>
			<meta name={"viewport"} content={"width=device-width, initial-scale=1, viewport-fit=cover"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/657a773b0072400020e73057/images/Elips-full.png?v=2023-12-14T05:53:17.431Z"} type={"image/x-icon"} />
		</Helmet>
		<Box
			min-width="100px"
			min-height="100px"
			flex="1 1 0%"
			align-self="stretch"
			display="block"
			position="absolute"
			height="100%"
			width="100%"
		>
			<Section
				background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/657a773b0072400020e73057/images/21%20Albums-pc%20%281%29.png?v=2023-12-18T00:33:17.623Z) 50% 0% /contain no-repeat scroll padding-box"
				padding="0 0 0 0"
				justify-content="center"
				flex-direction="row"
				align-content="space-around"
				align-items="stretch"
				flex-wrap="wrap"
				display="inline-block"
				width="100%"
				height="inherit"
				lazy-load={false}
				sm-background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/657a773b0072400020e73057/images/21%20Albums-mobile%20%281%29.png?v=2023-12-16T06:05:52.095Z) 0% 0% /contain no-repeat scroll padding-box"
			>
				<Override
					slot="SectionContent"
					margin="0px 0 0px 0"
					max-width="100%"
					width="inherit"
					flex="1 1 0%"
					height="100%"
					align-items="center"
					flex-direction="row"
					justify-content="center"
					flex-wrap="wrap"
					align-content="stretch"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					flex="1 1 0%"
					height="100%"
					background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/657a773b0072400020e73057/images/21%20Albums-pc%20%285%29.png?v=2023-12-14T05:02:28.818Z) 0% 0% /cover no-repeat scroll border-box"
					xl-background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/657a773b0072400020e73057/images/21%20Albums-tablet.png?v=2023-12-16T05:35:19.306Z) 0% 0% /cover no-repeat scroll border-box"
					sm-background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/657a773b0072400020e73057/images/21%20Albums-mobile%20%282%29.png?v=2023-12-16T06:41:16.265Z) 0% 0% /contain repeat-y scroll border-box"
					lg-overflow-x="scroll"
					lg-overflow-y="scroll"
				>
					<Section
						flex="1 1 0%"
						height="inherit"
						align-items="center"
						justify-content="space-between"
						flex-wrap="wrap"
						align-content="center"
						align-self="stretch"
						background="rgba(0, 0, 0, 0)"
					>
						<Override
							slot="SectionContent"
							flex-direction="row"
							flex="1 1 0%"
							height="100%"
							sm-flex-direction="column-reverse"
						/>
						<Section flex="0 1 auto" flex-direction="column-reverse" sm-margin="0 0 0% 0">
							<Override
								slot="SectionContent"
								align-items="flex-end"
								flex-direction="column-reverse"
								min-width="none"
								min-height="none"
								flex="1 1 0%"
								align-content="flex-end"
								padding="0px 0px 10% 10%"
							/>
							<Link
								href="https://forms.gle/rcFeVwWAKSxjmYgP7"
								target="_blank"
								color="rgba(0, 0, 0, 0)"
								background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/657a773b0072400020e73057/images/Group%2048.png?v=2023-12-16T04:39:22.486Z) center/contain no-repeat"
								align-self="center"
								width="80px"
								height="36px"
								text-decoration-line="initial"
								mix-blend-mode="lighten"
								hover-background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/657a773b0072400020e73057/images/Group%2043.png?v=2023-12-14T05:24:07.380Z) center/contain no-repeat"
								xl-background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/657a773b0072400020e73057/images/Group%2043.png?v=2023-12-14T05:24:07.380Z) center/contain no-repeat"
								sm-width="60px"
								sm-height="27px"
							>
								Some text
							</Link>
						</Section>
						<Section flex="0 1 auto" md-width="0%" />
						<Section flex="0 1 auto" flex-direction="column" padding="24px 10px 24px 0">
							<Override
								slot="SectionContent"
								flex="1 1 0%"
								justify-content="space-between"
								padding="25% 0px 25% 0px"
								sm-padding="0 0px 0 0px"
							/>
							<Section>
								<Box
									align-self="center"
									height="36px"
									background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/657a773b0072400020e73057/images/on%20the%20razzle%20%284%29%201%20%281%29.png?v=2023-12-14T05:15:28.731Z) 0% 0% /cover repeat scroll padding-box"
									width="300px"
									sm-width="200px"
									sm-height="24px"
								/>
							</Section>
							<Section sm-margin="10% 0 10% 0">
								<Override
									slot="SectionContent"
									order="0"
									flex-direction="row"
									flex="1 1 0%"
									flex-wrap="wrap"
								/>
								<Box align-self="center" text-align="left" flex="1 1 0%" sm-margin="0px 0px 0px 40%">
									<Link
										href="https://ronescroft.bandcamp.com"
										color="#dce7f2"
										display="block"
										font="normal 100 14px khand"
										text-align="left"
										text-decoration-line="initial"
										letter-spacing="2px"
										hover-color="rgba(77, 45, 255, 0.7)"
										target="_blank"
										xl-hover-color="#dce7f2"
										sm-font="normal 100 11px khand"
									>
										melcher ronescroft
									</Link>
									<Text
										margin="0px 0px 0px 0px"
										color="#dce7f2"
										font="normal 100 14px khand"
										letter-spacing="2px"
										text-align="left"
										sm-font="normal 100 11px khand"
									>
										{"  "}
									</Text>
									<Link
										href="https://ronescroft.bandcamp.com/album/elementary"
										color="#dce7f2"
										display="block"
										font="normal 100 14px khand"
										text-align="left"
										text-decoration-line="initial"
										letter-spacing="2px"
										hover-color="rgba(243, 57, 32, 0.7)"
										target="_blank"
										xl-hover-color="#dce7f2"
										sm-font="normal 100 11px khand"
									>
										elementary (ep) - 2024
									</Link>
									<Link
										href="https://ronescroft.bandcamp.com/track/trace-love"
										color="#dce7f2"
										display="block"
										font="normal 100 14px khand"
										text-align="left"
										text-decoration-line="initial"
										letter-spacing="2px"
										hover-color="rgba(77, 45, 255, 0.7)"
										target="_blank"
										xl-hover-color="#dce7f2"
										sm-font="normal 100 11px khand"
									>
										trace love - 2023
									</Link>
									<Link
										href="https://ronescroft.bandcamp.com/track/far-from-reality"
										color="#dce7f2"
										display="block"
										font="normal 100 14px khand"
										text-align="left"
										text-decoration-line="initial"
										letter-spacing="2px"
										hover-color="rgba(77, 45, 255, 0.7)"
										target="_blank"
										xl-hover-color="#dce7f2"
										sm-font="normal 100 11px khand"
									>
										far from reality - 2023
									</Link>
									<Link
										href="https://ronescroft.bandcamp.com/track/chromium-drifts"
										color="#dce7f2"
										display="block"
										font="normal 100 14px khand"
										text-align="left"
										text-decoration-line="initial"
										letter-spacing="2px"
										hover-color="rgba(77, 45, 255, 0.7)"
										target="_blank"
										xl-hover-color="#dce7f2"
										sm-font="normal 100 11px khand"
									>
										chromium drifts - 2023
									</Link>
									<Link
										href="https://ronescroft.bandcamp.com/track/cumulus"
										color="#dce7f2"
										display="block"
										font="normal 100 14px khand"
										text-align="left"
										text-decoration-line="initial"
										letter-spacing="2px"
										hover-color="rgba(77, 45, 255, 0.7)"
										target="_blank"
										xl-hover-color="#dce7f2"
										sm-font="normal 100 11px khand"
									>
										cumulus - 2023
									</Link>
									<Link
										href="https://ronescroft.bandcamp.com/track/cable-connection"
										color="#dce7f2"
										display="block"
										font="normal 100 14px khand"
										text-align="left"
										text-decoration-line="initial"
										letter-spacing="2px"
										hover-color="rgba(77, 45, 255, 0.7)"
										target="_blank"
										xl-hover-color="#dce7f2"
										sm-font="normal 100 11px khand"
									>
										cable connection - 2023
									</Link>
								</Box>
							</Section>
							<Section>
								<Override
									slot="SectionContent"
									flex-direction="row"
									display="flex"
									justify-content="space-between"
									align-items="center"
									position="relative"
									width="100%"
									padding="0px 60% 0px 0px"
									sm-margin="0px 0px 0px 40%"
									sm-padding="0px 45% 0px 0px"
								/>
								<Link
									href="https://ronescroft.bandcamp.com/"
									color="#000000"
									display="block"
									width="14px"
									height="14px"
									text-decoration-line="initial"
									background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/657a773b0072400020e73057/images/bandcamp.png?v=2023-12-14T05:04:52.323Z) 50% 0% /100% repeat scroll padding-box"
									target="_blank"
									sm-width="11px"
									sm-height="11px"
								/>
								<Link
									href="https://open.spotify.com/artist/65qAMAexak9Ku9se07L3uW?si=v0Dx856fSjS9GauJIA5HGw"
									color="#000000"
									display="block"
									width="14px"
									height="14px"
									text-decoration-line="initial"
									background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/657a773b0072400020e73057/images/spotify.png?v=2023-12-14T05:06:41.541Z) 50% 0% /100% repeat scroll padding-box"
									target="_blank"
									sm-width="11px"
									sm-height="11px"
								/>
								<Link
									href="https://music.youtube.com/channel/UCLM0eieONgzWqdIpqjiQmog"
									color="#000000"
									display="block"
									width="14px"
									height="14px"
									text-decoration-line="initial"
									background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/657a773b0072400020e73057/images/play.png?v=2023-12-14T05:07:16.592Z) 50% 0% /100% repeat scroll padding-box"
									target="_blank"
									sm-width="11px"
									sm-height="11px"
								/>
								<Link
									href="https://twitter.com/ronescroft"
									color="#000000"
									display="block"
									width="14px"
									height="14px"
									text-decoration-line="initial"
									background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/657a773b0072400020e73057/images/twitter%20%281%29.png?v=2023-12-14T05:08:01.312Z) 50% 0% /100% repeat scroll padding-box"
									target="_blank"
									sm-width="11px"
									sm-height="11px"
								/>
								<Link
									href="https://www.youtube.com/@mronescroft"
									color="#000000"
									display="block"
									width="14px"
									height="14px"
									text-decoration-line="initial"
									background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/657a773b0072400020e73057/images/youtube.png?v=2023-12-14T05:07:35.312Z) 50% 0% /100% repeat scroll padding-box"
									target="_blank"
									sm-width="11px"
									sm-height="11px"
								/>
							</Section>
						</Section>
					</Section>
				</Box>
			</Section>
		</Box>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"657a773b0072400020e73055"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});
